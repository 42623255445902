.terminal {
    color: #00a600;
}

@media (max-width: 1199.98px) {
    .ex-1-font {
        font-size: 2em;
    }
}

@media (max-width: 991.98px) {
    .ex-1-font {
        font-size: 1.7em;
    }
 }
.tab {
    margin-left: 4em;
}